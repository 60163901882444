import Api from "../../Api";

const END_POINT_ALLROLES = 'user/roles/all';
const END_POINT_CREATEROLES = 'user/roles/create';
const END_POINT_UPDATEROLES = 'user/roles/update';
const END_POINT_DELETEROLES = 'user/roles/delete';

export default {

    GetAllRoles(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLROLES, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateRoles(data, auth) {
        return Api.post(END_POINT_CREATEROLES, {
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateByRoles(data, auth) {
        return Api.patch(END_POINT_UPDATEROLES, {
            'id': data.id,
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByRoles(id, auth) {
        return Api.delete(END_POINT_DELETEROLES + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }

}