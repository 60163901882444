import Api from "../../Api";

const END_POINT_ALLPERMISSIONS = 'user/permissions/all';
const END_POINT_CREATEPERMISSIONS = 'user/permissions/create';

export default {

    GetAllPermissions(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLPERMISSIONS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreatePermissions(data, auth) {
        return Api.post(END_POINT_CREATEPERMISSIONS, {
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    }

}