<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Usuarios"
      subtitle="| Administrador de Usuarios, Roles y Permisos"
      class="heading-block"
    >
    </base-page-heading>

    <!-- END Hero -->
    <!-- Block Tabs Default Style -->
    <b-tabs
      class=" block"
      nav-class="nav-tabs-block"
      content-class="block-content"
      v-model="tabIndex"
    >
        <!-- Usuarios -->
        <usuario></usuario>

        <!-- Roles -->
        <roles></roles>

        <!-- Permisos -->
        <permisos></permisos>

    </b-tabs>
    <!-- END Block Tabs Default Style -->
  </div>
</template>

<script>

import usuario from './resources/usuarios.vue'
import roles from './resources/roles.vue'
import permisos from './resources/permisos.vue'

export default {
  components: {
    usuario,
    roles,
    permisos
  },
  data() {
    return {
      tabIndex: 0,
      sortBy: "id",
      sortDesc: false,
      selectMode: "multi",
      selected: null,
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectSecondRow() {
      // Rows are indexed from 0, so the second row is index 1
      this.$refs.selectableTable.selectRow(1);
    },
    unselectSecondRow() {
      // Rows are indexed from 0, so the second row is index 1
      this.$refs.selectableTable.unselectRow(1);
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>
