<template>
  <div>
    <div class="mt-5 mt-sm-0 ml-sm-3">
              <!--Modal Permisos-->
                <b-modal
                    id="modal-permisos"
                    size="md"
                    body-class="p-0"
                    hide-footer
                    hide-header
                >
                    <div
                    class="block block-rounded block-themed block-transparent mb-0"
                    >
                    <div class="block-header bg-primary-dark">
                        <h3 class="block-title">
                        <i class="fa fa-user mr-1"></i> Crear Permiso
                        </h3>
                        <div class="block-options">
                        <button
                            type="button"
                            class="btn-block-option"
                            @click="$bvModal.hide('modal-permisos')"
                        >
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                        </div>
                    </div>
                    <div class="block-content font-size-sm">
                        <form ref="form">
                        <b-row class="m-2">
                            <b-col sm="3">
                            <label for="input-small">* Nombre del permiso:</label>
                            </b-col>
                            <b-col sm="7">
                            <b-form-input
                                id="input-small"
                                size="sm"
                                placeholder="Ingresa permiso"
                            ></b-form-input>
                            </b-col>
                        </b-row>
                        <hr />                     
                        <b-row class="m-2">
                            <b-col sm="8">
                            <label for="input-small">Asignar rol:</label>
                            <select class="form-control">
                              <option :value="data.id" v-for="data in roles" :key="data.id">{{ data.name }}</option>
                            </select>
                            </b-col>
                        </b-row>
                        </form>
                    </div>
                    <div
                        class="block-content block-content-full text-right border-top"
                    >
                        <b-button
                        variant="alt-primary"
                        class="mr-1"
                        @click="$bvModal.hide('modal-permisos')"
                        >Cerrar</b-button
                        >
                        <b-button variant="primary" @click="$bvModal.hide('modal-permisos')"
                        >Guardar</b-button
                        >
                    </div>
                    </div>
                </b-modal>
              <!--Modal Permisos-->
    </div>
    <b-tab title="Permisos"  class="ml-5">
        <template #title>
          <i class="fas fa-fw fa-shield-alt mr-1"></i> Permisos ({{ roles.length }})
        </template>

        <div>
          <b-card no-body>

            <b-tabs pills card vertical nav-wrapper-class="w-25 mb-4">
             
              <b-tab :title="data.name" active  v-for="data in roles" :key="data.id" v-on:click="setRolId(data.id)">

                <!--Permisos-->
                <b-card-text class="scrollable">
                  <div class="table-responsive">
                    <table class="table table-striped align-middle">
                      <thead>
                        <th v-for="area in menuareas" :key="area.id">
                          <td>{{ area.name }}</td>
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td v-for="area in menuareas" :key="area.id">
                            <b-row class="m-2">
                              <p>Crear </p> <input type="checkbox" class="mt-1 ml-2" v-on:click="checkPermission('create',area.id)" :checked="actualPermissions.some( e => e.slug === area.name+'-create')" />
                            </b-row>
                            <b-row class="m-2">
                              <p>Leer</p> <input type="checkbox" class="mt-1 ml-2" v-on:click="checkPermission('read',area.id)" :checked="actualPermissions.some( e => e.slug === area.name+'-read')" />
                            </b-row>
                            <b-row class="m-2">
                              <p>Actualizar</p> <input type="checkbox" class="mt-1 ml-2" v-on:click="checkPermission('update',area.id)" :checked="actualPermissions.some( e => e.slug === area.name+'-update')" />
                            </b-row>
                            <b-row class="m-2">
                              <p>Eliminiar</p> <input type="checkbox" class="mt-1 ml-2" v-on:click="checkPermission('delete',area.id)" :checked="actualPermissions.some( e => e.slug === area.name+'-delete')" />
                            </b-row>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                <hr />
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
    </b-tab>
    <b-row align-h="center">
      <b-col cols="1"><b-button variant="danger" @click="SaveRolPrivileges()" class="mb-5">Guardar</b-button></b-col>
    </b-row>
  </div>
</template>

<script>


import AdminPermissions from '../../../../api/admin/users/Permissions'
import AdminRoles from '../../../../api/admin/users/Roles'
import MenuArea from '../../../../api/admin/menu_areas/Menu_Areas'

import Swal from 'sweetalert2'
export default ({
    data() {
      return {
        tabIndex: 0,
        sortBy: "id",
        sortDesc: false,
        selectMode: "multi",
        selected: null,
        roles:[],
        menuareas:[],
        rolSelectedId:0,
        selectedPermissionsIds:[],
        selectedAreasIds:[],
        permissions:[],
        selectedRol:null,
        actualPermissions:[]
      };
    },
    methods: {
      
      linkClass(idx) {
        if (this.tabIndex === idx) {
            return ["bg-light", "text-dark"];
        } else {
            return ["bg-light", "text-dark"];
        }
      },

      AllRoles(){
        let auth = JSON.parse(localStorage.autentication)
          AdminRoles.GetAllRoles(auth).then( response => {
              this.roles = response.data.roles;
              if(this.roles.length > 0){
                this.selectedRol = this.roles.slice(-1)[0].id
                this.getRolePermissions(this.selectedRol)
              }
          })
          .catch((error) => {
              Swal.fire({
                title: 'Que mal!',
                text: error.message,
                icon: 'error',
              })
          });
      },

      AllAreas(){
        let auth = JSON.parse(localStorage.autentication)
          MenuArea.GetAllMenuArea(auth).then( response => {
              this.menuareas = response.data;
          })
          .catch((error) => {
              Swal.fire({
                title: 'Que mal!',
                text: error.message,
                icon: 'error',
              })
          });
      },

      CreatePermissions(){
        let auth = JSON.parse(localStorage.autentication)
        AdminPermissions.PostCreatePermissions(this.form, auth).then(response => {
            this.AllUsers();
            Swal.fire({
              title: 'Genial!',
              text: response.data.message,
              icon: 'success',
            })
            this.$bvModal.hide('modal-permissions')
        })
        .catch((error) => {
          Swal.fire({
            title: 'Que mal!',
            text: error.message,
            icon: 'error',
          })
        });
      },

      setRolId(id){
        const context = this
        context.selectedRol = id
        context.getRolePermissions(context.selectedRol)
      },

      checkPermission(permiso_string, area_id, fromGetRolePermissions){
        
        const context = this
        let promise = null

        // hay que determinar si el área ya esta en el arreglo de permisos
        if(typeof context.permissions[area_id] === "undefined"){
          promise = new Promise((resolve) => {
            context.permissions[area_id] = []
            context.permissions[area_id].push(area_id)
            resolve()
          })
        }

        let area_idArrayLength = 0
        let estaElemento = false

        if(promise){
          promise.then(function (){
            var arrayT = {}
            arrayT[0] = permiso_string
            area_idArrayLength = context.permissions[area_id].length
            
            for(let i=0; i<area_idArrayLength; i++){
              const item = context.permissions[area_id][i]
              console.log(context.permissions[area_id])
              const permiso = item[Object.keys(item)]
              const options = ["create","read","update","delete"]
              if(options.includes(permiso)){
                // quitar el elemento
                estaElemento = true
                if(area_idArrayLength>0){
                  context.permissions[area_id].splice(i,1)
                }
              }
            }
            if(!estaElemento){
              context.permissions[area_id].push(arrayT)
            }

            console.log(context.permissions)
          })
        } else {
          var arrayT = {}
          // antes de hacer el push tenemoes que validar si el permiso esta en el array,
          // si está entonces tenemos que quitarlo, pues significa que el usuario ha desactivado
          // la casilla de selección.
          area_idArrayLength = context.permissions[area_id].length
          if(!fromGetRolePermissions){
            for(let i=1; i<area_idArrayLength; i++){
              const item = context.permissions[area_id][i]
              const permiso = item[Object.keys(item)]
              const options = ["create","read","update","delete"]
              if(options.includes(permiso) && permiso == permiso_string){
                // quitar el elemento
                estaElemento = true
                if(area_idArrayLength>0){
                  context.permissions[area_id].splice(i,1)
                  break;
                }
              }
            }
          }

          if(!estaElemento){
            arrayT[area_idArrayLength-1] = permiso_string
            context.permissions[area_id].push(arrayT)
          }

          console.log(context.permissions)
        }
      },

      SaveRolPrivileges(){
        const context = this
        if(context.selectedRol == null || context.roles.length == 0){
          Swal.fire({
            title: 'Que mal!',
            text: "No hay rol seleccionado",
            icon: 'error',
          })

          return
        }

        const data = {
          "rol_id" : context.selectedRol,
          "permissions":context.permissions
        }
        

        let auth = JSON.parse(localStorage.autentication);
        MenuArea.UpdateUserPermissions(data, auth)
        .then((response) => {
          Swal.fire({
            title: 'Genial!',
            text: response.data.message,
            icon: 'success',
          })
        })
        .catch((error) => {
          Swal.fire({
            title: 'Que mal!',
            text: error.message,
            icon: 'error',
          })
        });
        
      },

      getRolePermissions(id){
        let auth = JSON.parse(localStorage.autentication)
        MenuArea.GetUserPermissions(id,auth).then( response => {
          const context = this

          context.permissions = []
          context.actualPermissions = response.data.permissions

          context.menuareas.map( area => {
            if(context.actualPermissions.some( e => e.slug === area.name+'-create')){
              console.log("madar a llamar el push"+area.name+'-create')
              context.checkPermission('create',area.id, true)
            }

            if(context.actualPermissions.some( e => e.slug === area.name+'-read')){
              console.log("madar a llamar el push"+area.name+'-read')
              context.checkPermission('read',area.id, true)
            }

            if(context.actualPermissions.some( e => e.slug === area.name+'-update')){
              console.log("madar a llamar el push"+area.name+'-update')
              context.checkPermission('update',area.id, true)
            }

            if(context.actualPermissions.some( e => e.slug === area.name+'-delete')){
              console.log("madar a llamar el push"+area.name+'-delete')
              context.checkPermission('delete',area.id,true)
            }
          })
          
        })
        .catch((error) => {
          Swal.fire({
            title: 'Que mal!',
            text: error.message,
            icon: 'error',
          })
        });
      }

    },

    mounted() {
      let me = this
      me.AllRoles();
      me.AllAreas();
    },
})
</script>
<style>
  .tab-content.col{width:50%;}
</style>