<template>
  <div>
    <div class="mt-5 mt-sm-0 ml-sm-3">
      <!--Modal Roles-->
      <b-modal
        id="modal-roles"
        size="md"
        body-class="p-0"
        hide-footer
        hide-header
      >
        <div class="block block-rounded block-themed block-transparent mb-0">
          <div class="block-header bg-primary-dark">
            <h3 class="block-title">
              <i class="fa fa-user mr-1"></i> Crear Roles
            </h3>
            <div class="block-options">
              <button
                type="button"
                class="btn-block-option"
                @click="$bvModal.hide('modal-roles')"
              >
                <i class="fa fa-fw fa-times"></i>
              </button>
            </div>
          </div>
          <div class="block-content font-size-sm">
            <form ref="form">
              <b-row class="m-2">
                <b-col sm="4">
                  <label for="input-small">* Nombre del rol:</label>
                </b-col>
                <b-col sm="7">
                  <b-form-input
                    id="input-small"
                    size="sm"
                    placeholder="Ingresa nombre del rol"
                    v-model="form.name"
                    class="mb-3"
                  ></b-form-input>
                </b-col>
              </b-row>
            </form>
          </div>
          <div class="block-content block-content-full text-right border-top">
            <b-button
              variant="alt-primary"
              class="mr-1"
              @click="$bvModal.hide('modal-roles')"
              >Cerrar</b-button
            >
            <template v-if="edit == false">
              <b-button variant="primary" @click="CreateRoles()"
                >Guardar</b-button
              >
            </template>
            <template v-else> 
              <b-button variant="danger" @click="UpdateRol()"
                >Actualizar</b-button
              >
            </template>
          </div>
        </div>
      </b-modal>
      <!--Modal Roles-->
    </div>
    <b-tab title="Roles" >
      <template #title>
        <i class="fas fa-fw fa-user-tag mr-1"></i> Roles ({{ roles.length }})
      </template>

      <!-- Selectable Table -->
      <div>
        <base-block rounded title="">
        <b-row>
          <b-col xl="12" class="">
            <b-button
              variant="alt-primary"
              class="float-right"
              href="javascript:void(0)"
              v-b-popover.hover.bottom="'Alta de Usuarios'"
              @click="ShowModalRoles()"
              style="width: 200px"
            >
              <i class="fa fa-plus-square mr-1"></i> Nuevo Rol 
            </b-button>
          </b-col>
        </b-row>
        <hr>
          <b-row>
            <b-col sm="6" xl="2" class="mr-2">
              <b-dropdown
                id="dropdown-default-light"
                variant="light"
                text="Acciones agrupadas"
              >
                <b-dropdown-item class="font-size-sm"
                  >Eliminar Seleccion</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" class="ml-3">
              <!-- Form Inline - Alternative Style -->
              <b-form class="mb-4" inline>
                <b-form-input
                  type="search"
                  class="form-control-alt mr-sm-2 mb-sm-0"
                  id="example-if-email2"
                  name="example-if-email2"
                  placeholder="Busqueda"
                  v-model="filter"
                ></b-form-input>
              </b-form>
              <!-- END Form Inline - Alternative Style -->
            </b-col>
            <b-col>
              <b-dropdown
                variant="light"
                toggle-class="text-decoration-none"
                no-caret
                class="float-right"
                right
              >
                <template #button-content>
                  <i class="si si-settings"></i>
                </template>
                <b-dropdown-item class="font-size-sm" href="#"
                  >Exportar Roles a Excel</b-dropdown-item
                >
                <b-dropdown-item class="font-size-sm" href="#"
                  >Descargar CSV</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                variant="light"
                toggle-class="text-decoration-none"
                no-caret
                class="mr-1 float-right"
                right
              >
                <template #button-content>
                  <i class="fas fa-fw fa-filter"></i>
                </template>
                <b-dropdown-item class="font-size-sm" href="#"
                  >Por Role</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
          <br />
          <b-overlay :show="showoverlay" rounded="sm">
              <div class="table-responsive">
                <b-table :items="roles" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
                  <template #cell(actions)="row">
                      <b-button size="sm" @click="EditRol(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-pencil-alt"></i>
                      </b-button>
                      <b-button size="sm" @click="DeleteRol(row.item.id)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-times"></i>
                      </b-button>
                  </template>
                </b-table>
              </div>
          </b-overlay>
          <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </base-block>
      </div>
      <!-- END Selectable Table -->
    </b-tab>
  </div>
</template>

<script>
import AdminRoles from "../../../../api/admin/users/Roles";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      edit: false,
      filter: null,
      timeout: null,
      roles: [],
      showoverlay:false,
      fields: [
        {key: 'id', sortable: true, label: 'ID'},
        {key: 'name', sortable: true, label: 'NOMBRE DEL ROL'},
        { key: 'actions', sortable: true, label: 'ACCIONES'},
      ],
      form: [{
          id: "",
          name: "",
      }],
      errors:[],
    };
  },

  computed: {
    totalRows() {
      return this.roles.length
    }
  },
  methods: {

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

    ShowModalRoles(){
        this.form = [{
            id: "",
            name: "",
        }],
        this.$bvModal.show("modal-roles");
        this.edit = false;
    },

    AllRoles() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      AdminRoles.GetAllRoles(auth)
        .then((response) => {
          this.roles = response.data.roles;
          this.showoverlay = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "Que mal!",
            text: error.message,
            icon: "error",
          });
        });
    },

    CreateRoles() {
      this.$bvModal.hide("modal-roles");
      if (this.form.name ) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          AdminRoles.PostCreateRoles(this.form, auth)
            .then((response) => {
              this.AllRoles();
              this.showoverlay=false;
              Swal.fire({
                title: "Genial!",
                text: response.data.message,
                icon: "success",
              });
              this.form = [{
                  id: "",
                  name: "",
              }],              
              this.$root.$emit("AllRoles_Permissions", "200");
            })
            .catch((error) => {
              Swal.fire({
                title: "Que mal!",
                text: error.message,
                icon: "error",
              });
            });
      }else{
          this.errors = []
          if (!this.form.name) {
              let me=this
              me.errors.push('El campo nombre es requerido')
          }        
          Swal.fire({
            title: "Corrija los siguientes errores",
            icon: "error",
            html: this.errors
                .map(item => `<p> <li>${item}</li></p>`)
                .join('')
          });
      }

    },

    EditRol(data) {
      this.edit = true;
      this.form = {
        id: data.id,
        name: data.name,
      };
      this.$bvModal.show("modal-roles");
    },


    UpdateRol(){
        this.showoverlay=true;
        this.$bvModal.hide("modal-roles");
        let auth = JSON.parse(localStorage.autentication);
        AdminRoles.UpdateByRoles(this.form, auth)
        .then((response) => {
          this.AllRoles();
          this.showoverlay=false;
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.form = [{
              id: "",
              name: "",
          }]          
        })
        .catch((error) => {
          Swal.fire({
            title: "Que mal!",
            text: error.message,
            icon: "error",
          });
        });
    },


    DeleteRol(id){
        Swal.fire({
            title: "¿Esta seguro de eliminar el registro?",
            text: "¿Eliminar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        })
        .then(resultado => {
            if (resultado.value) {
                this.showoverlay=true;
                let auth = JSON.parse(localStorage.autentication);
                AdminRoles.DeleteByRoles(id, auth)
                .then((response) => {
                  this.showoverlay=false;
                  this.AllRoles();
                  Swal.fire({
                    title: "Genial!",
                    text: response.data.message,
                    icon: "success",
                  });
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Que mal!",
                    text: error.message,
                    icon: "error",
                  });
                });
            } else {
                // Dijeron que no
                console.log("*NO se elimina la venta*");
            }
        });
        
    },

  },

  mounted() {
    let me = this;
    me.AllRoles();
  },
};
</script>
