<template>
  <div>
    <div class="mt-5 mt-sm-0 ml-sm-3">
      <!--Modal Usuario-->
      <b-modal
        id="modal-usuario"
        size="md"
        body-class="p-0"
        hide-footer
        hide-header
      >
        <div class="block block-rounded block-themed block-transparent mb-0">
          <div class="block-header bg-primary-dark">
            <h3 class="block-title">
              <i class="fa fa-user mr-1"></i> Alta de Usuario
            </h3>
            <div class="block-options">
              <button
                type="button"
                class="btn-block-option"
                @click="$bvModal.hide('modal-usuario')"
              >
                <i class="fa fa-fw fa-times"></i>
              </button>
            </div>
          </div>
          <div class="block-content font-size-sm">
            <form ref="form">
              
              <b-col sm="10">
                <b-form-group label-for="username">
                  <template #label>
                    Nombres y Apellidos <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="username"
                    name="username"
                    placeholder="Ingresa nombre y apellidos.."
                    v-model="form.name"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              
              </b-col>

              <b-col sm="8">
                <b-form-group label-for="correo">
                  <template #label>
                    Correo electronico <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="correo"
                    name="correo"
                    placeholder="Ingresa correo electronico.."
                    v-model="form.email"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              
              </b-col>


              <hr />

              <template v-if="edit == false">
                <b-row class="m-2">
                  <b-col sm="5">
                    <label for="input-small">* Contraseña:</label>
                  </b-col>
                  <b-col sm="6">
                    <b-form-input
                      id="input-small"
                      size="sm"
                      type="password"
                      placeholder="Ingresa contraseña"
                      v-model="form.password"
                    ></b-form-input>
                   
                  </b-col>
                </b-row>
                <b-row class="m-2">
                  <b-col sm="5">
                    <label for="input-small">* Confirmar contraseña:</label>
                  </b-col>
                  <b-col sm="6">
                    <b-form-input
                      id="input-small"
                      size="sm"
                      type="password"
                      placeholder="Confirmar contraseña"
                      v-model="form.password_confirmation"
                    ></b-form-input>
                  
                  </b-col>
                </b-row>
              </template>

              <hr />

              <b-col sm="8">
                <b-form-group label-for="rol">
                  <template #label>
                    Asignar Rol de Usuario <span class="text-danger">*</span>
                  </template>

                  <select v-model="form.rol_id" class="mt-3 form-control">
                    <option
                      :value="data.id"
                      v-for="data in roles"
                      :key="data.id"
                      size="sm"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                </b-form-group>
                
              </b-col>
            </form>
          </div>
          <div class="block-content block-content-full text-right border-top">
            <b-button
              variant="alt-primary"
              class="mr-1"
              @click="$bvModal.hide('modal-usuario')"
              >Cerrar</b-button
            >

            <template v-if="edit == false">
              <b-button variant="primary" @click="CreateUsers()"
                >Registrar Usuario</b-button
              >
            </template>
            <template v-else>
              <b-button variant="danger" @click="UpdateUser()"
                >Actualizar</b-button
              >
            </template>
          </div>
        </div>
      </b-modal>
      <!--Modal Usuario-->

      <!--Modal Reset Password-->
      <b-modal
        id="modal-reset-password"
        size="md"
        body-class="p-0"
        hide-footer
        hide-header
      >
        <div class="block block-rounded block-themed block-transparent mb-0">
          <div class="block-header bg-primary-dark">
            <h3 class="block-title">
              <i class="fa fa-user mr-1"></i> Cambiar contraseña de
              {{ data_user_reset.name }}
            </h3>
            <div class="block-options">
              <button
                type="button"
                class="btn-block-option"
                @click="$bvModal.hide('modal-reset-password')"
              >
                <i class="fa fa-fw fa-times"></i>
              </button>
            </div>
          </div>
          <div class="block-content font-size-sm">
            <form ref="form">
              <b-row class="m-2">
                <b-col sm="3">
                  <label for="input-small">* Nueva contraseña:</label>
                </b-col>
                <b-col sm="7">
                  <b-form-input
                    id="input-small"
                    size="sm"
                    placeholder="Nueva contraseña"
                    v-model="form.name"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="m-2">
                <b-col sm="3">
                  <label for="input-small">* Confirmar contraseña:</label>
                </b-col>
                <b-col sm="7">
                  <b-form-input
                    id="input-small"
                    size="sm"
                    placeholder="Confirmar contraseña"
                    v-model="form.name"
                  ></b-form-input>
                </b-col>
              </b-row>
              <hr />
            </form>
          </div>
          <div class="block-content block-content-full text-right border-top">
            <b-button
              variant="alt-primary"
              class="mr-1"
              @click="$bvModal.hide('modal-reset-password')"
              >Cerrar</b-button
            >
            <b-button variant="danger" @click="ResetPasswordUser()"
              >Cambiar contraseña</b-button
            >
          </div>
        </div>
      </b-modal>
      <!--Modal Reset Password-->
    </div>
    <b-tab title="Usuarios" active>
      <template #title>
        <i
          class="fas fa-fw fa-users mr-1"
          v-b-popover.hover.bottom="'Lista de Usuarios'"
        ></i>
        Usuarios ({{ users.length }})
      </template>
      <!-- Selectable Table -->
      <div>
        <base-block rounded title="">
          <b-row>
            <b-col xl="12">
              <b-button
                variant="alt-primary"
                class="float-right mb-1"
                href="javascript:void(0)"
               
                v-b-popover.hover.bottom="'Alta de Usuarios'"
                @click="ShowModal()"
                style="width: 200px"
               
              >
                <i class="fa fa-plus-square mr-1"></i> Nuevo usuario
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col sm="6" xl="2" class="mr-4">
              <b-dropdown
                id="dropdown-default-light"
                variant="light"
                text="Acciones agrupadas"
              >
                <b-dropdown-item class="font-size-sm"
                  >Habilitar Seleccion</b-dropdown-item
                >
                <b-dropdown-item class="font-size-sm"
                  >Deshabilitar Seleccion</b-dropdown-item
                >
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item class="font-size-sm"
                  >Eliminar Seleccion</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" class="">
              <!-- Form Inline - Alternative Style -->
              <b-form class="mb-4" inline>
                <b-form-input
                  type="search"
                  class="form-control-alt mr-sm-2 mb-sm-0"
                  id="example-if-email2"
                  name="example-if-email2"
                  placeholder="Busqueda"
                  v-model="filter"
                ></b-form-input>
              </b-form>
              <!-- END Form Inline - Alternative Style -->
            </b-col>
           
            <b-col>
              <b-dropdown
                variant="light"
                toggle-class="text-decoration-none"
                no-caret
                class="float-right"
                right
              >
                <template #button-content>
                  <i class="si si-settings"></i>
                </template>
                <b-dropdown-item class="font-size-sm" href="#"
                  >Exportar Usuarios a Excel</b-dropdown-item
                >
                <b-dropdown-item class="font-size-sm" href="#"
                  >Descargar CSV</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                variant="light"
                toggle-class="text-decoration-none"
                no-caret
                class="mr-1 float-right"
                right
              >
                <template #button-content>
                  <i class="fas fa-fw fa-filter"></i>
                </template>
                <b-dropdown-item class="font-size-sm" href="#"
                  >Por Nombre</b-dropdown-item
                >
                <b-dropdown-item class="font-size-sm" href="#"
                  >Por Departamento</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
          <br />
          <b-overlay :show="showoverlay" rounded="sm">
            <div class="table-responsive">
              <b-table :items="users" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-function="filterTable"  responsive striped hover  >
                  <template #cell(actions)="row">
                    <b-button size="sm" @click="EditUser(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                        <i class="fa fa-fw fa-pencil-alt"></i>
                    </b-button>
                    <b-button size="sm" @click="DeleteUser(row.item.id)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                        <i class="fa fa-fw fa-times"></i>
                    </b-button>
                    <b-button size="sm" @click="ShowResetPassword(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                        <i class="fa fa-fw fa-lock"></i>
                    </b-button>
                </template>
              </b-table>
            </div>
          </b-overlay>
          <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </base-block>
      </div>
      <!-- END Selectable Table -->
    </b-tab>
  </div>
</template>

<script>
import AdminUser from "../../../../api/admin/users/Users";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      edit: false,
      data_user_reset: [],
      user_search: "",
      filter: null,
      timeout: null,
      showoverlay:false,
      form: [
        {
          id: "",
          name: "",
          email: "",
          password: "",
          password_confirmation: "",
          rol_id: "",
        },
      ],
      fields: [
        {key: 'id',sortable: true, label: 'ID'},
        {key: 'name',sortable: true, label: 'NOMBRE'},
        {key: 'email',sortable: true, label: 'EMAIL'},
        {key: 'roles', formatter: value => {
              return value[0].name
          },sortable: true, label: 'ROL'},
        { key: 'actions',sortable: true, label: 'ACCIONES'},
      ],
      users: [],
      roles: [],
      errors:[],
    };
  },
  computed: {
    totalRows() {
      return this.users.length
    }
  },
  methods: {

    filterTable(row, filter) {
      if (row.age >= filter) {
        return false;
      } else {
        return true;
      }
    },
    
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

    ShowModal() {
      this.form = {
          id: '',
          name: '',
          email: '',
          rol_id: '',
      };
      this.$bvModal.show("modal-usuario");
      this.edit = false;
    },

    ShowResetPassword(data) {
      this.$bvModal.show("modal-reset-password");
      this.data_user_reset = data;
    },

    AllUsers() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      AdminUser.GetAllUsers(auth)
        .then((response) => {
          this.users = response.data;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    AllRoles() {
      let auth = JSON.parse(localStorage.autentication);
      AdminUser.GetAllRoles(auth)
        .then((response) => {
          this.roles = response.data.roles;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    CreateUsers() {
      this.$bvModal.hide("modal-usuario");
      if (this.form.name  && this.form.email  && this.form.password  && this.form.rol_id ) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          AdminUser.PostCreateUsers(this.form, auth)
            .then((response) => {              
              this.AllUsers();
              this.showoverlay=false;
              Swal.fire({
                title: "Genial!",
                text: response.data.message,
                icon: "success",
              });
              this.form = {
                id: '',
                name: '',
                email: '',
                rol_id: '',
              };
              
            })
            .catch((error) => {
                const obj = error.response.data.errors;
                Object.entries(obj).forEach( elements => 
                    Swal.fire({
                      title: "Que mal!",
                      text: elements[1][0],
                      icon: "error",
                    })
                );
            });
      }else{
          this.errors = []
          if (!this.form.name) {
              let me=this
              me.errors.push('El campo nombre es requerido')
          }

          if (!this.form.email) {
              let me=this
              me.errors.push('El campo email es requerido')
          }

          if (!this.form.password) {
              let me=this
              me.errors.push('El campo contraseña es requerido')
          }

          if (!this.form.password_confirmation) {
              let me=this
              me.errors.push('El campo confirmacion de contraseña es requerido')
          }

          if (!this.form.rol_id) {
              let me=this
              me.errors.push('Es necesario asignarle un rol al usuario')
          }
          Swal.fire({
            title: "Corrija los siguientes errores",
            icon: "error",
            html: this.errors
                .map(item => `<p> <li>${item}</li></p>`)
                .join('')
          });
      }
    },

    EditUser(data) {
      this.edit = true;
      this.form = {
        id: data.id,
        name: data.name,
        email: data.email,
        rol_id: data.roles[0].id,
      };
      this.$bvModal.show("modal-usuario");
    },

    UpdateUser() {
       this.$bvModal.hide("modal-usuario");
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      AdminUser.UpdateUsers(this.form, auth)
        .then((response) => {          
          this.AllUsers();
          this.showoverlay = false;
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.form = {
            id: '',
            name: '',
            email: '',
            rol_id: '',
          };
        })
        .catch((error) => {
          Swal.fire({
            title: "Que mal!",
            text: error.message,
            icon: "error",
          });
        });
    },

    DeleteUser(id){
      Swal.fire({
          title: "¿Esta seguro de eliminar el registro?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
      })
      .then(resultado => {
          if (resultado.value) {
              this.showoverlay = true;
              let auth = JSON.parse(localStorage.autentication);
              AdminUser.DeleteByUsers(id, auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });                
                this.AllUsers();
                this.showoverlay = false;
              })
              .catch((error) => {
                Swal.fire({
                  title: "Que mal!",
                  text: error.message,
                  icon: "error",
                });
              });
          } else {
              // Dijeron que no
              console.log("*NO se elimina la venta*");
          }
      });


       /*   */
    },

  },
  mounted() {
    let me = this;   
    me.AllUsers();
    me.AllRoles();
  },
};
</script>

<style>
th {
  width: 0 !important;
}
ul{
  list-style: none;
}
</style>
