import Api from "../../Api";


const END_POINT_ALLUSERS = 'user/users/all';
const END_POINT_CREATEUSERS = 'user/users/create';
const END_POINT_UPDATEUSERS = 'user/users/update';
const END_POINT_DELETEUSERS = 'user/users/delete';
const END_POINT_ALLROLES = 'user/roles/all';
const END_POINT_ALLPERMISSIONS = 'user/permissions/all';

export default {
    GetAllUsers(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLUSERS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    GetAllRoles(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLROLES, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    GetAllPermissions(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLPERMISSIONS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },


    PostCreateUsers(data, auth) {
        return Api.post(END_POINT_CREATEUSERS, {
            'name': data.name,
            'email': data.email,
            'password': data.password,
            'password_confirmation': data.password_confirmation,
            'rol_id': data.rol_id,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateUsers(data, auth) {
        return Api.patch(END_POINT_UPDATEUSERS, {
            'id': data.id,
            'name': data.name,
            'email': data.email,
            'rol_id': data.rol_id,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByUsers(id, auth) {
        return Api.delete(END_POINT_DELETEUSERS + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}