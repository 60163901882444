import Api from "../../Api";

const END_POINT_ALLMENUAREA = 'user/menuarea/all';
const END_POINT_UPDATEPERMISSIONS = 'user/menuarea/permissions/update';
const END_POINT_GETUSERPERMISSIONS = 'user/menuarea/permissions/getUserPermissions';

export default {
  GetAllMenuArea(data) {
      return Api.get(END_POINT_ALLMENUAREA, {
          headers: {
              'Authorization': 'Bearer ' + data.access_token
          }
      });
  },

  UpdateUserPermissions(data,auth){
    return Api.patch(END_POINT_UPDATEPERMISSIONS, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  GetUserPermissions(data,auth) {
    return Api.get(END_POINT_GETUSERPERMISSIONS + '/' + data,
    {
        headers: {
            'Authorization': 'Bearer ' + auth.access_token
        }
    });
  }  

}